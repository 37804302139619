@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.inspirationWrapper {
  :global {
    .knowledge-article-detail-section {
      max-width: 100%;
      flex: 0 0 100%;
      font-family: "Helvetica Now Text W05 Regular";
      font-size: 20px;
      color: $color-dark-gray;

      .article-type {
        font-family: "Helvetica Now Text W05 Medium";
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.35px;
        color: $color-dark-gray;
        padding-bottom: 10px;
      }
      .title {
        font-family: "Helvetica Now Text W05 Light";
        font-size: 60px;
        line-height: 68px;
        letter-spacing: -3.1px;
        color: $color-slate;
        padding-bottom: 45px;
      }
      p {
        font-family: "Helvetica Now Text W05 Regular";
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.65px;
        padding-bottom: 35px;
        color: $color-dark-gray;
      }
      h2 {
        font-family: "Helvetica Now Text W05 Light";
        font-size: 36px;
        line-height: 44px;
        color: $color-dark-gray;
        letter-spacing: -1.7px;
        margin-top: 50px;
        margin-bottom: 12px;
      }
      ul,
      ol {
        padding-top: 10px;
        margin: 0;
        list-style: none;
        li {
          font-family: "Helvetica Now Text W05 Regular";
          font-size: 20px;
          line-height: 28px;
          letter-spacing: -0.65px;
          color: $color-dark-gray;
          padding-bottom: 25px;
          padding-left: 15px;
          position: relative;
          list-style: none !important;
          list-style-type: none !important;
        }
      }
      ul {
        li {
          &::before {
            position: absolute;
            left: 0;
            top: 10px;
            content: "";
            background: $color-dark-gray;
            width: 5px;
            height: 5px;
          }
        }
      }
      ol {
        counter-reset: item;
        li {
          counter-increment: item;
          padding-left: 0;
          &:before {
            margin-right: 10px;
            content: counter(item) ".";
          }
        }
      }
      a {
        text-decoration: underline;
        color: $color-dark-gray;
        &:hover {
          color: $color-dark-gray;
        }
      }
      img {
        max-width: 100%;
        object-fit: contain;
      }
      iframe {
        max-width: 100%;
        object-fit: contain;
      }
      table {
        width: 100% !important;
      }
    }

    @media screen and (max-width: 992px) {
      .knowledge-article-detail-section {
        font-size: 16px;
        border-top: 1px solid $color-light-gray;
        padding-top: 25px;

        .title {
          font-size: 40px;
          line-height: 46px;
          letter-spacing: -2.5px;
          padding-bottom: 25px;
        }
        h2 {
          font-size: 32px;
          line-height: 40px;
          letter-spacing: -2.2px;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.55px;
          padding-bottom: 15px;
        }
        ul,
        ol {
          li {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.55px;
            padding-bottom: 15px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1023px) {
  .product-listing__row.d-block {
    margin-top: 0px !important;
  }
}
