@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";
.inspirationTileWrapper {
  :global {
    .inspiration-tile {
      max-width: 100%;
      border-top: 1px solid $color-dark-gray;
      padding-top: 31px;
      padding-bottom: 50px;

      &__container {
        display: grid;
        grid-template-columns: 3fr 1fr;
        &.--full-width {
          grid-template-columns: 1fr;
        }
      }

      &__left-section-container {
        display: grid;
        grid-template-columns: 2fr 1fr;
        &.--full-width {
          grid-template-columns: 1fr 1fr;
        }
      }

      &__link {
        cursor: pointer;
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }

      &__category-name {
        display: block;
        font-family: "Helvetica Now Text W05 Medium";
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: -0.25px;
        color: $color-dark-gray;
        margin-bottom: 10px;
      }

      &__article-title {
        text-decoration: none;
        font-family: "Helvetica Now Text W05 Light";
        font-size: 32px;
        line-height: 1.25;
        letter-spacing: -1.5px;
        color: $color-slate;
        padding-right: 3.75vw;
      }

      &__article-sub-title {
        display: block;
        font-family: "Helvetica Now Text W05 Regular";
        font-size: 20px;
        line-height: 1.4;
        letter-spacing: -0.65px;
        color: $color-dark-gray;
        margin-top: 10px;
      }

      &__description {
        display: block;
        font-family: "Helvetica Now Text W05 Regular";
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
      }

      &__image-container {
        height: 145px;
      }

      &__img {
        width: 145px;
        height: 145px;
        object-fit: cover;
        float: right;
        transition: all 0.5s ease-in-out;
      }

      @media all and (max-width: 992px) {
        padding-top: 25px;
        padding-bottom: 20px;
      }

      @media only screen and (max-width: 812px) and (orientation: landscape) {
        border-top: 1px solid $color-dark-gray;
        padding-top: 20px;
        padding-bottom: 20px;

        &__category-name {
          font-size: 10px;
          line-height: 22px;
          letter-spacing: -0.35px;
        }
        &__container {
          display: grid;
          grid-template-columns: 3fr 1fr;
          gap: 30px;
        }
        &__left-section-container {
          grid-template-columns: 1fr 1fr;
          gap: 50px;
          &__first-coloumn {
            width: 272px;
          }
          &__second-coloumn {
            width: 272px;
          }
        }
        &__title,
        &__description {
          padding-right: 0px;
        }
        &__article-title {
          font-size: 20px;
          line-height: 26px;
          letter-spacing: -1.15px;
        }
        &__article-sub-title {
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: -0.55px;
          margin-top: 10px;
        }
        &__description {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.55px;
          -webkit-line-clamp: 5;
        }
        &__img {
          width: 103px;
          height: 103px;
        }
      }

      @media screen and (max-width: 768px) {
        &__container {
          display: grid;
          grid-template-columns: 3fr 1fr;
          gap: 25px;
        }
      }

      @media screen and (max-width: 480px) {
        border-top: 1px solid $color-dark-gray;
        padding-top: 25px;
        padding-bottom: 35px;

        &__img {
          width: 80px;
          height: 80px;
        }
        &__category-name {
          margin-bottom: 10px;
          line-height: 1.38;
          letter-spacing: -0.25px;
        }
        &__article-title {
          font-size: 20px;
          font-family: "Helvetica Now Text Light";
          line-height: 1.4;
          letter-spacing: -0.65px;
          margin-bottom: 10px;
        }
        &__article-sub-title {
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: -0.55px;
        }
        &__description {
          font-size: 14px;
          line-height: 1.57;
          letter-spacing: -0.65px;
          width: 100%;
          -webkit-line-clamp: 2;
        }
        &__left-section-container {
          grid-template-columns: 1fr;
        }
        &__container {
          display: grid;
          grid-template-columns: 3fr 1fr;
          gap: 25px;
        }
      }
    }
    .knowledge-article {
      .product-list {
        &__description {
          font-size: 2rem;
          line-height: 45px;
          letter-spacing: -1.6px;
          color: $color-dark-gray;
        }
        &__back-to-top {
          border-top: none;
          padding-top: 10px;
        }
      }
    }

    @media only screen and (max-width: 812px) and (orientation: landscape) {
      .product-listing .product-listing__load-more {
        margin-bottom: 40px;
      }
      .product-listing__back-to-top {
        padding-top: 15px;
      }
    }
  }
}
