@import "@/styles/base/_colors";
@import "@/styles/base/_fonts";

.technicalTileWrapper {
  :global {
    .technical-tile {
      display: grid;

      &__badge {
        display: flex;
        margin-bottom: 12px;
      }

      &__maxwidth {
        margin-left: 0px !important;
        max-width: 1020px;
      }

      &__zeropx {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }

      &__discontinued {
        font-family: "Helvetica Now Text W05 Medium";
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.65px;
        color: $color-white;
        margin-bottom: 0px;
        padding: 6px 8px;
        background-color: $color-slate;
        text-align: center;
      }
      &__image {
        max-width: 596px;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 1;
        &--product-image {
          position: relative;
          padding-top: 100%;
          width: 100%;
          height: 0px;
          div {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0px;
            top: 0px;
          }
        }

        img {
          width: 100%;
          height: 100%;
          mix-blend-mode: multiply;
          object-fit: cover;
        }
        .social-share__box {
          top: 14px;
          right: 0px;
          @media screen and (max-width: 992px) {
            right: auto;
            left: -14px;
          }
          @media screen and (max-width: 480px) {
            left: -50px;
          }
        }
      }

      &__sku {
        font-family: $font-regular;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: -0.65px;
        color: $color-dark-gray;
        padding-top: 8px;
      }

      &__brand-description {
        color: $color-dark-gray;
      }
      &__details {
        word-break: break-word;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        margin-bottom: 50px;
        h3 {
          font-family: "Helvetica Now Text W05 Light";
          font-size: 32px;
          line-height: 1.25;
          letter-spacing: -1.5px;
          color: $color-slate;
          margin-bottom: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        p {
          font-family: "Helvetica Now Text W05 Regular";
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: -0.55px;
          color: $color-dark-gray;
          margin-bottom: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      &__specs {
        max-width: none;
        margin-bottom: 50px;
        div {
          margin-top: 15px;
          &:first-child {
            margin-top: 0px;
          }
        }
      }
      &__specs-content {
        font-family: "Helvetica Now Text W05 Regular";
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.55px;
        color: $color-dark-gray;
        display: block;
      }
      &__collapsible-fullWidth-grid {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 1;
      }
      &__collapsible-grid {
        grid-column-start: 2;
        grid-column-end: 2;
      }
      &__collapsible {
        .accordion-container:first-child {
          .collapsible-accordion {
            .Collapsible {
              border-top: 1px solid $color-dark-gray;
            }
          }
        }
        .collapsible-accordion {
          .Collapsible {
            border-bottom: 1px solid $color-dark-gray;
            &__trigger {
              padding: 20px 0px;
              .collapsible__header {
                align-items: center;
                span {
                  font-family: "Helvetica Now Text W05 Light";
                  font-size: 22px;
                  line-height: 1.45;
                  letter-spacing: -1.2px;
                  color: $color-charcoal;
                }
              }
            }
          }
        }
        .Collapsible__contentInner {
          padding-bottom: 45px;
        }
      }
      &__add-share {
        display: flex;
        position: absolute;
        z-index: 1;
        right: 10px;
        top: 10px;

        span {
          font-family: $font-regular;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: -0.55px;
          color: $color-dark-gray;
          margin-left: 10px;
          cursor: pointer;
          padding: 10px;
          &:first-child {
            display: flex;
            align-items: center;
          }
        }
        img {
          height: fit-content;
          width: auto;
        }
      }
      &__download-section {
        &.technical-section-main {
          grid-template-columns: 1fr;
          grid-template-rows: 30px auto;
          grid-template-rows: none;
          .technical-title_sub_title {
            &.part_tile_download {
              margin: 20px 0 0 0;
            }
            font-size: 14px;
            line-height: 22px;
            color: $color-slate;
            letter-spacing: -0.3px;
            font-family: "Helvetica Now Text W05 Medium";
            @media screen and (max-width: 1024px) {
              margin: 10px 0;
            }
          }
          .technical-tile {
            &__files {
              section {
                padding: 0;
              }
            }
          }
        }
        display: grid;
        grid-template-columns: 1fr 240px;
        grid-template-rows: 40px auto;
        padding-top: 0px;
      }
      &__download-btn {
        grid-column-start: 2;
        font-family: "Helvetica Now Text W05 Medium";
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: -0.25px;
        color: $color-white;
        height: 40px;
        padding: 8px 30px;
        background: $color-kohler-blue;
        text-align: center;
      }
      &.disabled {
        color: $color-white;
        background: $color-light-gray;
      }
      &__files {
        cursor: pointer;
        padding-top: 0px;
        @media screen and (min-width: 1140px) {
          padding-top: 15px;
        }
        section {
          display: flex;
          padding-top: 20px;
          padding-bottom: 5px;

          &:last-child {
            border-bottom: none;
          }

          span.technical-tile__file-type {
            img {
              margin-right: 15px;
            }
          }

          span.technical-tile__file-type.m-right {
            margin-right: 30px;
            display: flex;
            align-items: center;
          }

          .technical-tile__file-type {
            font-family: "Helvetica Now Text W05 Medium";
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.35px;
            color: $color-dark-gray;
            width: 100%;
            max-width: 180px;
            display: flex;
            align-items: center;
          }
          .input-group {
            display: flex;
            .checkbox-wrapper {
              margin-bottom: 15px;
            }
          }
          .checkbox {
            margin-left: 30px;
            min-width: 68px;
          }
        }
        &.--technical-info {
          max-width: 416px;
          section {
            display: flex;
            align-items: center;
            .technical-tile__file-type {
              max-width: none;
            }
            .checkbox {
              margin-left: auto;
              margin-bottom: 0px !important;
            }
          }
        }
      }
      &--full-width {
        .technical-tile__specs {
          display: flex;
          grid-column-start: 2;
          grid-column-end: 2;
          grid-row-start: 1;
          grid-row-end: 1;
          div {
            margin-top: 0px;
            margin-right: auto;
          }
        }
      }
      .technical-tile__collapsible {
        .technical-tile__specs-container {
          .technical-tile__sku {
            font-family: $font-medium;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.3px;
            color: $color-slate;
          }
        }
      }
    }
    @media print {
      .breadcrumb .cmp-breadcrumb,
      .cmp-tabs {
        display: none;
      }
      .technical-specs {
        .product-listing {
          &__row {
            display: block;
            flex-wrap: nowrap;
          }

          &__filter,
          &__sticky-nav-wrap,
          &__back-to-top,
          &__load-more {
            display: none;
          }
          &__tile {
            &:first-child {
              margin-top: 30px;
            }
            break-inside: avoid;
            page-break-inside: avoid;
            margin-bottom: 60px;
            &-info {
              height: auto;
            }
            &-col {
              margin-left: 0px;
              .product-listing__row {
                .promo-col-1,
                .promo-col-2,
                .promo-col-3 {
                  display: none;
                }
              }
            }
          }
          &__total {
            max-height: 3.5rem;
          }
        }
        .technical-tile {
          &__add-share {
            display: none;
          }
          &__image {
            page-break-inside: avoid;
            page-break-after: auto;
            page-break-before: auto;
          }
        }
      }
    }

    @media screen and (min-width: 1024px) and (max-width: 1140px) {
      .technical-tile {
        &.--full-width {
          &__image {
            img {
              height: 100%;
            }
          }
        }
        &__details {
          margin-bottom: 0;
        }
      }
    }

    @media screen and (max-width: 992px) {
      .technical-tile {
        &__badge {
          margin-bottom: 10px;
        }
        &__image {
          img {
            height: 100%;
          }
        }
        &__brand-description {
          font-size: 14px;
        }
        &__sku {
          font-size: 14px;
          line-height: 1.57;
          letter-spacing: -0.65px;
          padding-top: 5px;
        }
        &__details {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 3;
          word-break: break-word;
          -webkit-hyphens: auto;
          -moz-hyphens: auto;
          -ms-hyphens: auto;
          hyphens: auto;
          margin-bottom: 0;

          h3 {
            font-size: 20px;
            line-height: 1.4;
            letter-spacing: -0.65px;
            margin: 5px 0px;
            font-family: $font-regular;
          }
          p {
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: -0.65px;
            margin-bottom: 15px;
          }
        }
        &__specs-subtitle {
          font-size: 14px;
          line-height: 1.57;
          letter-spacing: -0.35px;
          display: block;
        }
        &__specs-content {
          font-size: 14px;
          line-height: 1.57;
          letter-spacing: -0.65px;
          display: block;
        }
        &__collapsible {
          grid-column-start: 3;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 1;
          .collapsible-accordion {
            .Collapsible__trigger {
              padding: 15px 0px;
            }
          }
          .Collapsible__contentInner {
            padding-bottom: 15px;
          }
        }

        &__download-section {
          grid-template-columns: 1fr;
          grid-template-rows: auto;
          padding-top: 10px;
          .button {
            grid-column-start: 1;
            grid-row-start: 3;
          }
        }
        &__select-all {
          margin-bottom: 30px;
          .checkbox {
            margin: 0px;
          }
        }
        &__download-btn {
          grid-column-start: 1;
          grid-row-start: 3;
          margin-top: 20px;
          margin-bottom: 25px;
        }
        &__files {
          section {
            padding: 15px 0px;
          }
          &.--technical-info {
            section {
              .checkbox {
                margin-left: auto;
                margin-right: 0px;
              }
            }
          }
        }
        &__specs {
          div {
            margin-top: 0;
          }
        }
      }
      .product-listing__row.d-block {
        margin-top: 0;
      }
    }

    @media screen and (max-width: 990px) and (orientation: landscape) {
      .technical-tile {
        grid-column-gap: 30px;
      }
    }

    @media screen and (max-width: 768px) {
      .technical-tile {
        &__maxwidth {
          margin-left: -15px !important;
        }

        &__image {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 1;
          grid-row-end: 2;
        }
        &__details {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 2;
        }
        &__specs {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 2;
          grid-row-end: 3;
          margin-top: 0px;
          margin-bottom: 25px;
        }
      }
      .product-listing__tile {
        padding-right: 0px;
        &-col {
          margin: 0;
        }
      }
    }

    @media screen and (max-width: 767px) {
      .technical-tile {
        &__download-btn {
          margin: 10px 0px 0px 0px;
        }
        &__select-all {
          margin-bottom: 30px;
          .checkbox {
            margin: 0px;
          }
        }
        &__collapsible {
          .Collapsible__contentInner {
            padding-bottom: 45px;
          }
        }
      }
    }

    @media screen and (max-width: 640px) {
      .technical-tile {
        grid-template-columns: 123px 2fr;
        &__details {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 1;
        }
        &__collapsible {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row: none;
          margin-top: 0;
          border-top: 0;
          max-width: none;
        }
        &__specs {
          margin: 25px 0;
          div {
            margin-top: 0px;
          }
        }
      }
    }
  }
}
